<template>
  <v-col v-if="brand.id" cols="4">
    <div class="font-weight-bold text-h5 mb-4">Thương hiệu</div>
    <v-card class="tp-filter-scroll pa-5" flat>
      <v-card-title class="font-weight-bold pa-0 mb-5">
        {{ brand.id ? "Cập nhật thương hiệu" : "Thêm thương hiệu" }}
      </v-card-title>

      <v-form ref="brandForm" lazy-validation>
        <v-container class="pa-0" fluid>
          <v-row>
            <v-col class="py-0" cols="12">
              <div class="font-weight-bold mb-2">Tên thương hiệu</div>
              <tp-text-field
                v-model="brand.name"
                placeholder="Nhập tên thương hiệu"
                validate="required"
              ></tp-text-field>
            </v-col>

            <v-col class="py-0" cols="12">
              <div class="font-weight-bold mb-2">Mô tả</div>
              <v-textarea
                v-model="brand.description"
                background-color="white"
                class="text-body-1"
                counter
                dense
                filled
                maxlength="320"
                outlined
                placeholder="Thêm mô tả"
                single-line
                validate="required"
              ></v-textarea>
            </v-col>

            <v-col class="py-0" cols="12">
              <v-checkbox
                v-model="brand.is_premium"
                class="mt-0"
                label="Thương hiệu Premium"
              ></v-checkbox>
            </v-col>

            <v-col class="py-0 mt-1" cols="12">
              <div class="d-flex align-center justify-space-between mb-1">
                <div class="font-weight-bold">Ảnh Cover</div>
              </div>

              <tp-input-image
                :acceptedFileTypes="['image/*']"
                :maxFileSize="500 * 1024"
                :src.sync="brand.cover"
                allowFileSize
                allowFileTypeValidation
                class="flex-grow-1"
                forceType="jpeg"
              ></tp-input-image>
            </v-col>

            <v-col class="py-0 mt-1" cols="12">
              <div class="d-flex align-center justify-space-between mb-1">
                <div class="font-weight-bold">
                  Ảnh Thumbnail (social link Facebook)
                </div>
              </div>

              <tp-input-image
                :acceptedFileTypes="['image/*']"
                :maxFileSize="500 * 1024"
                :src.sync="brand.thumbnail"
                allowFileSize
                allowFileTypeValidation
                class="flex-grow-1"
                forceType="jpeg"
              ></tp-input-image>
            </v-col>

            <v-col class="py-0" cols="12">
              <div class="d-flex align-center justify-space-between mb-1">
                <div class="font-weight-bold">Icon</div>
              </div>

              <div class="amber lighten-5 rounded text-body-2 px-2 py-1 mb-2">
                Kích thước ảnh yêu cầu là
                <span class="font-weight-bold">512 x 512 px</span>
              </div>

              <tp-input-image
                :acceptedFileTypes="['image/png']"
                :height="256 * 2"
                :maxFileSize="300 * 1024"
                :src.sync="brand.icon"
                :width="256 * 2"
                allowFileDimensionValidation
                allowFileSize
                allowFileTypeValidation
                class="flex-grow-1"
                forceType="png"
                required
              ></tp-input-image>
            </v-col>

            <v-col class="py-0" cols="12">
              <div class="mb-2 font-weight-bold">Bài viết</div>

              <autocomplete-article
                v-model="brand.social_post_id"
                :article="brand.social_post"
              ></autocomplete-article>
            </v-col>

            <v-col class="py-0" cols="12">
              <div v-if="type && type === 'brand'" class="py-0">
                <div class="font-weight-bold mb-2">Thương hiệu chính hãng</div>
                <v-checkbox
                  v-model="brand.isAuthentic"
                  :false-value="0"
                  :true-value="1"
                  class="mt-0"
                  label="Chính hãng"
                ></v-checkbox>
                <tp-textarea
                  v-model="brand.noteAuthentic"
                  :disabled="brand.isAuthentic === 0"
                  background-color="white"
                  class="text-body-1"
                  counter
                  dense
                  filled
                  maxlength="320"
                  outlined
                  placeholder="Nhập ghi chú cho thương hiệu chính hãng"
                  required
                  single-line
                ></tp-textarea>
              </div>
            </v-col>

            <v-col class="py-0 mt-1 mb-4" cols="12">
              <div class="font-weight-bold mb-2">
                Loại thương hiệu cha
              </div>
              <treeselect
                v-model="brand.parent_id"
                :options="this.brandTrees"
                class="custom-treeselect-category"
                height="100"
                placeholder="Chọn thương hiệu"
                search-nested
              ></treeselect>
            </v-col>

            <v-col class="py-0 mt-1 mb-4" cols="12">
              <form-seo-data
                :key="brand.id"
                v-model="brand.seo_data"
              ></form-seo-data>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-card-actions class="px-0 pb-0 d-flex justify-end">
        <v-btn
          class="grey lighten-5 rounded-lg mr-2"
          color="grey--text text--darken-1"
          text
          @click="resetBrand()"
        >
          Hủy
        </v-btn>
        <v-btn
          v-if="brand.id"
          :loading="brandStatusRequest.value === 'loading-createBrand'"
          class="mr-2"
          color="error"
          depressed
          @click="deleteProductType()"
        >
          Xóa
        </v-btn>
        <v-btn
          v-if="brand.id"
          :loading="brandStatusRequest.value === 'loading-createBrand'"
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          text
          @click="updateBrand()"
        >
          Lưu
        </v-btn>
        <v-btn
          v-else
          :loading="brandStatusRequest.value === 'loading-updateBrand'"
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          text
          @click="createBrand()"
        >
          Thêm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
import { toFormData } from "@vt7/utils";
import { showModalAlertError, showToastActionAlert } from "@/core/composables";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { LIST_MODEL_TYPES } from "@/core/constant";
import AutocompleteArticle from "./AutocompleteArticle.vue";
import FormSeoData from "@/core/components/form/FormSeoData/index.vue";

export default {
  components: { FormSeoData, AutocompleteArticle, Treeselect },

  props: {
    type: {
      type: String
    }
  },
  data() {
    return {
      LIST_MODEL_TYPES,
      modelSiteConfigs: [],
      thumbnailDevice: "MOBILE"
    };
  },
  computed: {
    brand() {
      return this.$store.getters["BRAND/brand"];
    },
    brandStatusRequest() {
      return this.$store.getters["BRAND/statusRequest"];
    },
    subBrand() {
      return this.$store.getters["BRAND/subBrand"];
    },
    uploadedCover() {
      return this.$store.getters["BRAND/uploadedCover"];
    },
    uploadedIcon() {
      return this.$store.getters["BRAND/uploadedIcon"];
    },
    brandTrees() {
      return this.$store.getters["BRAND/brandTrees"];
    },
    categoryTrees() {
      return this.$store.getters["PRODUCT_TYPE/categoryTrees"];
    }
  },
  methods: {
    resetBrand() {
      this.$store.dispatch("BRAND/resetBrand");
      this.$refs.brandForm.resetValidation();
    },

    async onUploadCover() {
      await this.uploadCover(
        toFormData({
          file: this.brand.cover
        })
      );

      // Set uploaded thumbnail
      if (this.brandStatusRequest.value === "success-uploadCover") {
        this.brand.cover = this.uploadedCover;
      } else if (this.brandStatusRequest.value === "error-uploadCover") {
        showModalAlertError(this, {
          title: "Lỗi tải lên ảnh bìa",
          message: this.brandStatusRequest.message
        });

        return;
      }
    },

    async onUploadThumbnail() {
      await this.uploadCover(
        toFormData({
          file: this.brand.thumbnail
        })
      );

      // Set uploaded thumbnail
      if (this.brandStatusRequest.value === "success-uploadCover") {
        this.brand.thumbnail = this.uploadedCover;
      } else if (this.brandStatusRequest.value === "error-uploadCover") {
        showModalAlertError(this, {
          title: "Lỗi tải lên ảnh bìa",
          message: this.brandStatusRequest.message
        });

        return;
      }
    },

    async onUploadIcon() {
      await this.uploadIcon(
        toFormData({
          file: this.brand.icon
        })
      );

      // Set uploaded thumbnail
      if (this.brandStatusRequest.value === "success-uploadIcon") {
        this.brand.icon = this.uploadedIcon;
      } else if (this.brandStatusRequest.value === "error-uploadIcon") {
        showModalAlertError(this, {
          title: "Lỗi tải lên ảnh đại diện",
          message: this.brandStatusRequest.message
        });

        return;
      }
    },

    async updateBrand() {
      const isValid = this.$refs.brandForm.validate();

      if (!isValid) return;

      // TODO: Write reused function

      if (this.brand.cover instanceof File) {
        await this.onUploadCover();
      }

      if (this.brand.thumbnail instanceof File) {
        await this.onUploadThumbnail();
      }

      // Check mobile thumbnail changes
      if (this.brand.icon instanceof File) {
        await this.onUploadIcon();
      }

      await this.$store.dispatch("BRAND/updateBrand", {
        data: this.brand
      });

      // Alert
      if (this.brandStatusRequest.value === "success-updateBrand") {
        // get brands after change to display
        this.$store.dispatch("BRAND/getAllBrands");

        showToastActionAlert(this, { message: "Đã lưu thay đổi" });
      } else if (this.brandStatusRequest.value === "error-updateBrand") {
        showModalAlertError(this, {
          title: "Không thể cập nhật loại sản phẩm",
          message: this.brandStatusRequest.message
        });
      }
    },

    async deleteProductType() {
      // Confirm
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa loại sản phẩm <strong>"${this.brand.name}"</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              await this.$store.dispatch("BRAND/deleteBrands", {
                data: {
                  ids: [this.brand.id]
                }
              });
              if (this.brandStatusRequest.value === "success-deleteBrands") {
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa thành công"
                  }
                });
                this.$store.dispatch("BRAND/getAllBrands");
              }
            }
          }
        }
      });
    },

    async uploadCover(formData) {
      if (!formData) return;

      await this.$store.dispatch("BRAND/uploadCover", formData);
    },
    async uploadIcon(formData) {
      if (!formData) return;

      await this.$store.dispatch("BRAND/uploadIcon", formData);
    }
  }
};
</script>

<style lang="scss">
.custom-treeselect-category .vue-treeselect__value-container {
  font-size: 14px;
}
</style>
